<template>
  <!-- 逾期租户名单 -->
    <section class="slippage-tenant " style="width:100%">
        <!-- <div class="flex justify-between align-center">
            <div class="title" style="margin-left: 20px;">逾期租户名单</div>

            <div style="margin-right: 30px;display: flex;">
              <el-input v-model="formSearch.keyword" placeholder="请输入关键词搜索" clearable size="small" />
              <el-button style="margin-left: 12px;" size="mini" type="primary" @click="changeSaixuan">查 询</el-button>
              <el-button @click="handleReset" size="small">重置</el-button>
            </div>
        </div> -->
        <div class="content bg-white padding_10_15">
            <r-e-table  class="bg-white" ref="orderTableRef" :data="getTableDatalist" :columns="tableColumn"
                       :query="formSearch" :height="425" :showPagination="false"
                       @selection-change="handleSelectionChange">
                <template slot="empty">
                    <el-empty/>
                </template>
            </r-e-table>
        </div>
    </section>
    
</template>

<script>
import {slippageTableColumn} from "@/views/home/data";
import {getHomeLateLeasorAPI, batchReminderAPI} from '@/api/home';
import {MessageSuccess, MessageInfo} from "@custom/message";
import {getAddressList} from "@/api/community-management";
import el from "element-ui/src/locale/lang/el";

export default {
    name: "slippage-tenant",
    data() {
        return {
          optionss: [{
            value: '选项1',
            label: '黄金糕'
          }, {
            value: '选项2',
            label: '双皮奶'
          }, {
            value: '选项3',
            label: '蚵仔煎'
          }, {
            value: '选项4',
            label: '龙须面'
          }, {
            value: '选项5',
            label: '北京烤鸭'
          }],
          value: '',
          houseTypes:[],
            tableColumn: slippageTableColumn(this),
            formSearch: {
                keyword: null,
            },
            uuids: null,
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
          options:[],
          getTableDatalist:[],
          currentPage4: 1,
          page:{
            totalNum:'',
            page:'1',
            pageSize:'10',
            totalPage: '10'
          }
        };
    },
    components: {
    },
     created() {
        this.getTableData()
      },
  watch:{
    async 'formSearch.apartmentCode'(value) {
      if (value !== 0) {
        this.formSearch.apartmentCodeson = null;
        this.houseTypes = await this.$store.dispatch('app/getDictionaryByCodeActions', value);
      } else {
        this.formSearch.apartmentCodeson = null;
        this.houseTypes = [];
      }
    },
  },
  async mounted() {
      let res = await getAddressList();
      this.options = res.list;
    this.options.unshift('全部')
    },
    methods: {
      handleSizeChange(e){
        console.log(e)
        this.page.pageSize = e
        this.getTableData()
      },
      handleCurrentChange(e){
        console.log(e)
        this.page.page = e
        this.getTableData()
      },
        // 数据请求方法
        getTableData(params) {
          console.log('我被请求了')
          // console.log(params)
          console.log(getHomeLateLeasorAPI({district: this.formSearch.district}))
          getHomeLateLeasorAPI({...this.formSearch,...this.page}).then(res => {
            


            console.log(res)
            this.page =res.page
            // projectList.value = list.sort((a, b) => {
            //   return b.usedVolume - a.usedVolume;
            // });
            this.getTableDatalist = res.list
            this.getTableDatalist = this.getTableDatalist.sort((a, b) => {
              return b.lateDays - a.lateDays;
            });
          })
        },
        handleSelectionChange(row) {
            if (row.length > 0) {
                this.uuids = row.map(({contractNum}) => contractNum).join(';');
            } else {
                this.uuids = null;
            }
        },
      // 筛选小区
      changeSaixuan(){
         this.getTableData()
          console.log(this.formSearch)

      },
      handleReset() {
                this.formSearch = {};
                Promise.resolve(this.formSearch).then(() => this.changeSaixuan());
      },
    }
}
</script>

<style lang="scss" scoped>
.slippage-tenant {
    // width: 100%;
    .title {
        height: VH(55px);
        line-height: VH(55px);
        color: #666;
        padding-left: VW(10px);
        position: relative;

        &::before {
            width: 5px;
            height: 40%;
            background-color: #5C84FB;
            content: '';
            position: absolute;
            left: 0;
            top: 30%;
        }
    }
}
</style>
