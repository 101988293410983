<template >
    <!-- 小区动态 -->
    <section class="community-dynamic" >
        <div class="flex justify-between align-center">
            <div class="title" style="margin-left: 20px;">小区动态</div>

            <div style="margin-right: 30px;display: flex;">
              <el-input v-model="formSearch.keyword" placeholder="请输入关键词搜索" clearable size="small" />
              <el-button style="margin-left: 12px;" size="mini" type="primary" @click="handleSearch">查 询</el-button>
              <el-button @click="handleReset" size="small">重置</el-button>
            </div>
        </div>
        
        <div  style="margin: 10px 0;padding-bottom: 50px;background-color: white;">
        <r-e-table ref="communityTableRef" :dataRequest="getCommunityApi" :columns="dynamicTableColumn"
                   :query="formSearch" :height="425" :notAuto="true" :show-pagination="false"
                   :treeProps="{children: 'children', hasChildren: true}">
                   <template slot="empty">
                    <el-empty/>
                </template>
            </r-e-table>
          <el-pagination
             style="text-align: center"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage4"
              :page-sizes="[10, 20, 40, 80]"
              :page-size="100"
              layout="total, sizes, prev, pager, next,jumper"
              :total="page.totalNum">
          </el-pagination>
        </div>
                   
    </section>
</template>

<script>
import {dynamicTableColumn} from "@/views/home/data";
import {getCommunityApi} from "@/api/home";
import {getAddressList} from "@/api/community-management";

export default {
    name: "community-dynamic",
    data() {
        return {
            tableData: [
                /*{
                    a1: '金色地中海2期',
                    a2: '2',
                    a3: '2',
                    a4: '1',
                    a5: '1',
                    a6: '0',
                    a7: '2',
                },
                {
                    a1: '富航217青年公寓',
                    a2: '201',
                    a3: '185',
                    a4: '55',
                    a5: '40',
                    a6: '10',
                    a7: '185',
                }*/
            ],
            dynamicTableColumn: dynamicTableColumn(this),
            formSearch: {
                keyword:null,
            },
            houseTypes: [],
            options: [],
            currentPage4: 1,
            page:{
            totalNum:'',
            page:'1',
            pageSize:'10',
            totalPage: '10'
          }
        };
    },
    props: {
        collectionData: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        handleSizeChange(e){
        console.log(e)
        this.page.pageSize = e
        this.getCommunityApi()
      },
      handleCurrentChange(e){
        console.log(e)
        this.page.page = e
        this.getCommunityApi()
      },
        //小区动态列表
        getCommunityApi(params) {
            let oldParams = params;
          // let  listmix  = []
            let collectionData = this.collectionData;
            let keyword = { keyword: this.formSearch.keyword }; // 构造 keyword 参数对象
            params = { ...oldParams, ...collectionData, };
          // getCommunityApi(params).then(res => {
          //   let list = res.list
          //   listmix.push(list[1])
          // })
          // console.log(listmix)
        //   console.log(getCommunityApi(params))
            return getCommunityApi(params);
          // return listmix
        },
        //查询
        handleSearch() {
            this.$refs["communityTableRef"].getTableData();
        },
              // 筛选小区
      handleReset() {
                this.formSearch = {};
                Promise.resolve(this.formSearch).then(() => this.handleSearch());
      },
    },
    watch: {
        collectionData(value) {
            this.handleSearch();
        }
    },
    async mounted() {
        //获取查询条件参数
        /*this.houseTypes = await this.$store.dispatch('app/getDictionaryByCodeActions', 600210);
        let list = await this.$store.dispatch('app/getDictionaryByCodeActions', 600220);
        this.houseTypes = this.houseTypes.concat(list);
        let res = await getAddressList();
        this.options = res.list;*/
        this.handleSearch();
    }
};
</script>

<style lang="scss" scoped>
/deep/ .r-e-table {
    .el-table .cell {
        //  white-space: nowrap;
        text-align: center;
    }
}
.community-dynamic {
    width: 49%;
    .title {
        height: VH(55px);
        line-height: VH(55px);
        color: #666;
        padding-left: VW(10px);
        position: relative;

        &::before {
            width: 5px;
            height: 40%;
            background-color: #5C84FB;
            content: '';
            position: absolute;
            left: 0;
            top: 30%;
        }
    }
}

</style>
