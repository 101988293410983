<template>
    <section class="check-out padding_10_15">
        <div class="top-bar bg-white">
            <el-date-picker v-model="formSearch.start" type="daterange" value-format="yyyy-MM-dd" format="yyyy-MM-dd"
                            style="margin: 0 10px;" size="small" range-separator="至" start-placeholder="开始日期"
                            end-placeholder="结束日期"/>
            <el-button type="primary" size="small" @click="handleSearch">查询</el-button>
            <el-button @click="handleReset" size="small">重置</el-button>
        </div>

        <r-e-table class="bg-white" ref="tableRef" :dataRequest="getHomeAlert" :columns="tableColumn" :query="formSearch"
                   tooltip-effect="light" :height="800">
            <template slot="empty">
                <el-empty/>
            </template>
            <el-table-column slot="toolbar" label="操作" width="200">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" v-if="row.dismissed===false" @click="handle(row)">处理</span>
                    </div>
                </template>
            </el-table-column>
        </r-e-table>
    </section>
</template>

<script>
    import {tableCheckOutColumn} from "@/views/home/data";
    import {getHomeAlert} from "@/api/home";
    import {dismiss} from "@/api/log";
    import {MessageSuccess} from "@custom/message";
    export default {
        name: "check-out",
        data() {
            return {
                tableColumn: tableCheckOutColumn,
                formSearch: {},
            };
        },
        mounted() {

        },
        methods: {
            //查询退房信息
            getHomeAlert(params) {
                params.type = "退房";
                return getHomeAlert(params);
            },
            //查询
            handleSearch() {
                this.$refs["tableRef"].getTableData();
            },
            //重置
            handleReset() {
                this.formSearch = {};
                Promise.resolve(this.formSearch).then(() => this.handleSearch());
            },
            //已读退房信息
            handle(data){
                let {url, uuid} = data;
                this.$confirm('此操作将会确认已读该条消息, 是否继续', '提示', {
                    confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning',
                }).then(() => {
                    dismiss(uuid).then(res => {
                        MessageSuccess('操作成功!');
                        //判断是否携带跳转路由
                        if (url) {
                            //拆分路由和参数
                            let urlList = url !== "" ? url.split("+-+").filter(item => item !== "") : [];
                            let paramsData = {};
                            let propList = urlList[1].split(";").filter(item => item !== "") !== 0 ? urlList[1].split(";").filter(item => item !== "") : [];
                            //判断时候有参数
                            if (propList.length !== 0) {
                                //组装参数
                                for (let item of propList) {
                                    let propArr = item.split(":").filter(item => item !== "") !== 0 ? item.split(":").filter(item => item !== "") : [];
                                    if (propArr.length !== 0) paramsData[propArr[0]] = propArr[1];
                                }
                            }
                            //跳转对应界面
                            this.$router.push({path: urlList[0], query: {data: paramsData}});
                        }
                    })
                }).catch(() => {});
            }
        }
    };
</script>

<style lang="scss" scoped>
    .check-out {
        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(25px);
            padding: VH(15px) VW(15px);
            justify-content: flex-start;
        }

        /deep/ .r-e-table {
            .el-table .cell {
                white-space: nowrap;
                text-align: center;
            }
        }
    }
</style>
