<template>
  <!-- 即将到期合同 -->
    <section class="coming-due">
      <div class="flex justify-between align-center">
            <div class="title" style="margin-left: 20px;">即将到期合同</div>

            <div style="margin-right: 30px;display: flex;">
              <el-input v-model="formSearch.keyword" placeholder="请输入关键词搜索" clearable size="small" />
              <el-button style="margin-left: 12px;" size="mini" type="primary" @click="changeSaixuan">查 询</el-button>
              <el-button @click="handleReset" size="small">重置</el-button>
            </div>
        </div>
        <div style="margin: 10px 0;padding-bottom: 50px;background-color: white;">
            <r-e-table class="bg-white" ref="orderTableRef" :data="getTableDatalist" :columns="tableColumn"
                       :query="formSearch" :height="425" :showPagination="false">
                <template slot="empty">
                    <el-empty/>
                </template>
            </r-e-table>
          <el-pagination
              style="text-align: center"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :current-page="currentPage4"
              :page-sizes="[10, 20, 40, 80]"
              :page-size="100"
              layout="total, sizes, prev, pager, next,jumper"
              :total="page.totalNum">
          </el-pagination>
        </div>
    </section>
</template>

<script>
import {comingDue} from "@/views/home/data";
import {getHomeExpirationAPI} from "@/api/home"
import {getAddressList} from "@/api/community-management";

export default {
    name: "coming-due",
    data() {
        return {
          houseTypes:[],
            tableColumn: comingDue(this),
            formSearch: {
                keyword: null,
            },
          options:[],
          getTableDatalist:[],
          currentPage4: 1,
          page:{
            totalNum:'',
            page:'1',
            pageSize:'10',
            totalPage: '10'
          }
        };
    },
    components: {},
  async  mounted() {
     let res = await getAddressList();
     console.log(res.list)
     this.options = res.list;
     this.options.unshift('全部')
     console.log(this.options,'this.options')
  },
   created() {
    this.getTableData()

  },
  watch:{
    async 'formSearch.apartmentCode'(value) {
      if (value !== 0) {
        this.formSearch.apartmentCodeson = null;
        this.houseTypes = await this.$store.dispatch('app/getDictionaryByCodeActions', value);
      } else {
        this.formSearch.apartmentCodeson = null;
        this.houseTypes = [];
      }
    },
  },
    methods: {
      handleSizeChange(e){
        console.log(e)
        this.page.pageSize = e
        this.getTableData()
      },
      handleCurrentChange(e){
        console.log(e)
        this.page.page = e
        this.getTableData()
      },
        getTableData(params) {
          // console.log(getHomeExpirationAPI(params))
          //   return getHomeExpirationAPI(params);
          getHomeExpirationAPI({...this.formSearch,...this.page}).then(res => {
            console.log(res.list)
            this.page =res.page
            this.getTableDatalist = res.list
            this.getTableDatalist = this.getTableDatalist.sort((a, b) => {
              return   a.remainingDays -b.remainingDays
            });
          })
        },
        // 筛选小区
      async changeSaixuan(){
        console.log(this.formSearch)
        await this.getTableData(this.formSearch)
      },
      handleReset() {
                this.formSearch = {};
                Promise.resolve(this.formSearch).then(() => this.changeSaixuan());
      },
    }
}
</script>

<style lang="scss" scoped>
.coming-due {
    width: 49%;

    .title {
        height: VH(55px);
        line-height: VH(55px);
        color: #666;
        padding-left: VW(10px);
        position: relative;

        &::before {
            width: 5px;
            height: 40%;
            background-color: #5C84FB;
            content: '';
            position: absolute;
            left: 0;
            top: 30%;
        }
    }
}
</style>