<template>
    <section class="renewal-to-examine">
        <div class="top-bar bg-white" style="justify-content: left">
            <el-date-picker v-model="value1" type="daterange" value-format="yyyy-MM-dd" format="yyyy-MM-dd" size="small"
                            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="margin-right: 10px;"/>
          <el-input v-model="formSearch.proposer" size="small" style="width: 200px;margin-right: 10px;" placeholder="请输入申请人"/>
          <el-input v-model="formSearch.approver" size="small" style="width: 200px;margin-right: 10px;" placeholder="请输入审批人"/>
          <el-input v-model="formSearch.roomNumber" size="small" style="width: 200px;margin-right: 10px;" placeholder="请输入房间号"/>
<!--            <el-select v-model="formSearch.natureCode" placeholder="请选择状态" style="width: 200px;margin-right: 10px" size="small">-->
<!--                <el-option v-for="item in natureCodeList" :label="item.codeName" :value="item.code" :key="item.code"/>-->
<!--            </el-select>-->
            <el-button type="primary" @click="handleSearch" size="small">查询</el-button>
            <el-button @click="handleReset" size="small">重置</el-button>
        </div>
        <r-e-table class="bg-white" ref="tableRef" :dataRequest="getContractTableData" :columns="tableColumn"
                   :query="formSearch" :height="760">
            <template slot="empty">
                <el-empty/>
            </template>
            <el-table-column slot="toolbar" label="操作" width="200">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" @click="details(row)">查看</span>
                        <span class="table-btn" v-if="row.auditStatus === '审批中' && getButtonMenu('RENEWAL_APPROVAL')" @click="audit(row)">同意</span>
                        <span class="table-btn" v-if="row.auditStatus === '审批中' && getButtonMenu('RENEWAL_APPROVAL')" @click="refuse(row)">拒绝</span>
                    </div>
                </template>
            </el-table-column>
        </r-e-table>

        <r-e-dialog title="拒绝" :visible.sync="refuseDialogVisible" show-footer top="10vh" width="700px"
                    @click-submit="refuseClickSubmit" @click-cancel="refuseClickCancel" @close="refuseClickCancel">
            <el-form ref="formPublish" label-width="80px" size="small" :model="formPublish" :rules="rules">
                <el-form-item label="拒绝原因" prop="turnCause">
                    <el-input v-model="formPublish.turnCause" type="textarea" :rows="4" placeholder="请输入拒绝原因"/>
                </el-form-item>
            </el-form>
        </r-e-dialog>

        <r-e-dialog title="详情" :visible.sync="detailsDialogVisible" top="10vh" width="700px">
            <el-form ref="formPublish" label-width="125px" size="small" :model="detailsData">
                <div class="flex">
                    <el-form-item label="房源信息">
                        <el-input v-model="detailsData.apartment.name" disabled placeholder="请填写房型名称（15个字以内）" style="width: 520px;" />
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="申请人">
                        <el-input type="text" v-model="detailsData.leasor.name" disabled placeholder="" />
                    </el-form-item>
                    <el-form-item label="申请时间">
                        <el-date-picker disabled v-model="detailsData.createdTime" type="date" placeholder="选择日期" style="width: 200px;"/>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="租赁时间" prop="monthSize">
                        <div class="flex justify-between" style="width: 200px;">
                            <el-input-number v-model="detailsData.monthSize" style="width: 160px" :min="1" label="租聘时间" disabled />
                            <span>(月)</span>
                        </div>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="续租开始日期">
                        <el-date-picker disabled v-model="detailsData.startDate" type="date" placeholder="选择日期" style="width: 200px;"/>
                    </el-form-item>
                    <el-form-item label="续租结束日期">
                        <el-date-picker disabled v-model="detailsData.endDate" type="date" placeholder="选择日期" style="width: 200px;"/>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="紧急联系人">
                        <el-input type="text" v-model="detailsData.leasor.contactName" disabled placeholder="请填写紧急联系人" />
                    </el-form-item>
                    <el-form-item label="紧急联系人电话">
                        <el-input type="text" v-model="detailsData.leasor.contactPhone" disabled :maxlength="11" placeholder="请填写紧急联系人电话" />
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="身份证地址">
                        <el-input type="text" v-model="detailsData.leasor.addressId" disabled placeholder="请填写身份证地址" />
                    </el-form-item>
                    <el-form-item label="邮箱">
                        <el-input type="text" v-model="detailsData.leasor.mailAddress" disabled placeholder="请填写邮箱" />
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="月租金" prop="amount">
                        <el-input type="number" v-model="detailsData.apartment.amount" disabled placeholder="请填写月租金"/>
                    </el-form-item>
                    <el-form-item label="服务费" prop="tip">
                        <el-input type="number" v-model="detailsData.apartment.tipAmount" disabled placeholder="请填写服务费"/>
                    </el-form-item>
                </div>
            </el-form>
        </r-e-dialog>

    </section>
</template>

<script>
    import { renewalTableColumn } from "@/views/home/data";
    import { getContractAudit, audit, turn, info, isRelet } from "@/api/contract";
    import { MessageSuccess } from "@custom/message";
    import { ParseFloatFormat } from "@custom/index";
    import { buttonCookies } from "@storage/cookies";
    export default {
        name: "renewal-to-examine",
        data() {
            return {
                tableColumn: renewalTableColumn(this),
                formSearch: {
                    keyWord: null,
                    startDate: null,
                    endDate: null,
                  proposer:null,
                  approver:null,
                  status:null,
                },
                natureCodeList: [],
                value1: [],
                loadingOptions: {
                    lock: true,
                    text: "Loading...",
                    spinner: "el-icon-loading",
                    background: "rgba(0, 0, 0, 0.7)"
                },
                refuseDialogVisible: false,
                formPublish: {
                    uuid: null,
                    turnCause: null,
                },
                rules: {
                    turnCause: [{required: true, message: '请上传核销图片', trigger: 'blur'}],
                },
                detailsDialogVisible: false,
                detailsData: {
                    createdTime: null,
                    leasor: {},
                    apartment: {},
                    monthSize: null,
                    startDate: null,
                    endDate: null,
                    contractNum: null,
                }
            };
        },
        components: {},
        methods: {
            //续租列表
            getContractTableData(params) {
                return getContractAudit(params);
            },
            //查询
            handleSearch() {
                this.$refs["tableRef"].getTableData();
            },
            //重置
            handleReset() {
                this.formSearch = {};
                this.value1 = []
                Promise.resolve(this.formSearch).then(() => this.handleSearch());
            },
            // 同意续租申请
            audit(data) {
                let that = this;
                let {uuid} = data;
                this.$confirm('此操作将会同意此申请, 是否继续', '提示', {
                    confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning',
                }).then(() => {
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading({...loadingOptions});
                    isRelet(uuid).then(res => {
                        let {info} = res;
                        if(info) {
                            audit(uuid).then(res => {
                                MessageSuccess('操作成功!');
                                that.handleSearch();
                            }).finally(() => loading.close());
                        } else {
                            loading.close();
                            const h = that.$createElement;
                            that.$msgbox({title: '注意',message:h('div', {style:{color:'red', fontSize: 16, fontWeight: 600}}, '存在未缴纳账单，需要缴纳完毕后方可续租'), type: 'warning'})
                                .then(() => {}).catch(() => {});
                        }
                        }).catch(() => loading.close());
                }).catch(() => {});
            },
            //打开拒绝申请弹窗
            refuse(data) {
                let {uuid} = data;
                this.formPublish.uuid = uuid;
                this.refuseDialogVisible = true;
            },
            //拒绝续租申请确认
            refuseClickSubmit() {
                let that = this;
                let {uuid, turnCause} = that.formPublish;
                this.$refs["formPublish"].validate((valid) =>{
                    if (valid){
                        this.$confirm('此操作将会拒绝此申请, 是否继续', '提示', {
                            confirmButtonText: '确定', cancelButtonText: '取消', type: 'warning',
                        }).then(() => {
                            const loadingOptions = that.loadingOptions;
                            const loading = that.$loading({...loadingOptions});
                            turn(uuid, turnCause).then(res => {
                                MessageSuccess('操作成功!');
                                that.refuseClickCancel();
                            }).finally(() => loading.close());
                        }).catch(() => {});
                    }
                });
            },
            //拒绝续租申请取消
            refuseClickCancel() {
                this.formPublish.uuid = null;
                this.formPublish.turnCause = null;
                this.refuseDialogVisible = false;
                this.handleSearch();
            },
            //详情
            async details(data) {
                let that = this;
                let {uuid} = data;
                const loadingOptions = that.loadingOptions;
                const loading = that.$loading({...loadingOptions});
                let res = await info(uuid).finally(() => loading.close());
                if(res) {
                    let {info} = res;
                    info.apartment.amount = ParseFloatFormat(info.apartment.amount);
                    info.apartment.tipAmount = ParseFloatFormat(info.apartment.tipAmount);
                    that.detailsData = {...info};
                    that.detailsDialogVisible = true;
                }
            },
            //获取按钮权限数组
            getButtonMenu(menu){
                return JSON.parse(buttonCookies.get()).map(({url}) => url).some(v => v === menu);
            },
        },
        async mounted() {
        },
    }
</script>

<style lang="scss" scoped>
    .renewal-to-examine {
        padding: VH(15px) VW(15px);

        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(25px);
            padding: VH(15px) VW(15px);
            justify-content: center;

            .el-radio-button.is-active {
                box-shadow: 0 0 10px #DDD inset;
            }
        }

        /deep/ .r-e-table {
            .el-table .cell {
                white-space: nowrap;
                text-align: center;
            }
        }
    }
</style>