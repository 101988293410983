<template>
    <section class="complaint padding_10_15">
        <div class="top-bar bg-white">
            <!-- 20-23-9-8-陈守亮-投诉优化 -->
            <div class="form_selest">投诉单搜索</div>
            <el-select v-model="formSearchvalue" placeholder="全部" size="small" style="margin: 0 10px">
                <el-option :label="item.title" :value="item.id" v-for="item in aeroplane" :key="item.id"/>
            </el-select>
            <el-input v-model="formSearchvaluetow" v-show="formSearchvalue === ''" @input="formSearchvaluechange"
                      size="small" placeholder="请输入内容" style="margin: 0 9px; width: 250px"/>
            <el-input v-model="formSearch.leasor" v-show="formSearchvalue === 'leasor'" size="small"
                      placeholder="请输入投诉人" style="margin: 0 9px; width: 250px"/>
            <el-input v-model="formSearch.feedSn" v-show="formSearchvalue === 'feedSn'" size="small"
                      placeholder="请输入投诉编号" style="margin: 0 9px; width: 250px"/>
            <el-input v-model="formSearch.phone" v-show="formSearchvalue === 'phone'" size="small"
                      placeholder="请输入联系人" style="margin: 0 9px; width: 250px"/>
            <!-- 结尾 -->
            <el-select v-model="formSearch.feedType" placeholder="请选择投诉类型" size="small" style="margin: 0 10px">
                <el-option label="功能使用" value="700301"/>
                <el-option label="优化建议" value="700302"/>
                <el-option label="新增功能" value="700303"/>
                <el-option label="其他问题" value="700304"/>
            </el-select>
            <el-select v-model="formSearch.state" placeholder="请选择跟进状态" size="small" style="margin-right: 10px">
                <el-option label="未处理" :value="600401"/>
                <el-option label="已处理" :value="600402"/>
            </el-select>
            <!-- 2023/张晓瑜新增住房类型 -->
            <!-- <el-select v-model="formSearch.state" placeholder="请选择住房类型" size="small" style="margin-right: 10px">
                      <el-option label="未处理" :value="600401"/>
                      <el-option label="已处理" :value="600402"/>
                  </el-select> -->
            <el-date-picker v-model="formSearch.feedDate" type="date" size="small" value-format="yyyy-MM-dd"
                            format="yyyy-MM-dd" placeholder="选择投诉日期"/>
            <el-button class="chaxun_btn" type="primary" size="small" @click="handleSearch">查询</el-button>
            <el-button @click="handleReset" size="small">重置</el-button>
        </div>

        <r-e-table class="bg-white" ref="orderTableRef" :dataRequest="getFeedbackTableData" :columns="tableColumn"
                   :query="formSearch" tooltip-effect="light" :height="800">
            <template slot="empty">
                <el-empty/>
            </template>
            <el-table-column slot="toolbar" label="操作" width="200">
                <template slot-scope="{ row }">
                    <div class="table-tools">
                        <span class="table-btn" v-if="row.stateCode === 600401" @click="handle(row)">处理</span>
                        <span class="table-btn"
                              v-if="row.stateCode === 600402 && getButtonMenu('complaintToExamine')"
                              @click="toExamineShowTrue(row)">
                            审核
                        </span>
                    </div>
                </template>
            </el-table-column>
        </r-e-table>

        <r-e-dialog title="投诉处理" :visible.sync="dialogVisible" show-footer top="10vh" width="600px"
                    @click-submit="clickSubmit" @click-cancel="clickCancel" @close="clickCancel">
            <el-form ref="formPublish" label-width="110px" size="small" :model="formPublish" :rules="rules">
                <div class="flex">
                    <el-form-item label="处理内容" prop="feedContent">
                        <el-input type="textarea" :rows="2" v-model="formPublish.feedContent" style="width: 350px"/>
                    </el-form-item>
                </div>
            </el-form>
        </r-e-dialog>

        <r-e-dialog title="处理审核" :visible.sync="toExamineShow" show-footer top="10vh" width="600px"
                    @click-submit="toExamineSubmit" @click-cancel="toExamineCancel" @close="toExamineCancel">
            <el-form ref="formPublish1" label-width="110px" size="small" :model="formPublish" :rules="rules">
                <div class="flex">
                    <el-form-item label="处理内容" prop="feedContent">
                        <el-input type="textarea" :rows="2" v-model="formPublish.feedContent" style="width: 350px" readonly/>
                    </el-form-item>
                </div>
                <div class="flex">
                    <el-form-item label="审核状态" prop="authState">
                        <el-radio-group v-model="formPublish.authState">
                            <el-radio-button label="1">通过</el-radio-button>
                            <el-radio-button label="2">驳回</el-radio-button>
                        </el-radio-group>
                    </el-form-item>
                </div>
                <div class="flex" v-if="formPublish.authState === '2'">
                    <el-form-item label="驳回原因" prop="authFeedContent">
                        <el-input type="textarea" :rows="2" v-model="formPublish.authFeedContent" style="width: 350px"/>
                    </el-form-item>
                </div>
            </el-form>
        </r-e-dialog>
    </section>
</template>

<script>
import {tableComplaintColumn} from "@/views/home/data";
import {getFeedbackList, feedbackDealFeed, authFeed} from "@/api/home";
import {MessageSuccess} from "@custom/message";
import {buttonCookies} from "@storage/cookies";

export default {
    name: "complaint",
    data() {
        return {
            formSearchvalue: null,
            formSearchvaluetow: null,
            aeroplane: [
                {title: "全部", id: 1,},
                {title: "投诉编号", id: "feedSn",},
                {title: "投诉人", id: "leasor",},
                {title: "联系方式", id: "phone",},
                // {title: "租房地址", id: 1444,},
            ],
            housing: [
                {title: "富航公寓", id: "1",},
                {title: "回购经适房", id: "2",},
                {title: "拆迁安置房", id: "3",},
                {title: "竞配产权房", id: "4",},
                {title: "高级人才房", id: "5",},
                {title: "五象四号点", id: "6",},
            ],
            tableColumn: tableComplaintColumn,
            tableData: [],
            formSearch: {
                feedSn: null,
                leasor: null,
                feedDate: null,
                feedType: null,
                state: null,
                phone: null,
            },
            dialogVisible: false,
            formPublish: {
                feedContent: null,
                uuid: null,
                authFeedContent: null,
                authState: null,
            },
            rules: {
                feedContent: [{required: true, message: "请输入处理内容", trigger: "blur"},],
                authFeedContent: [{required: true, message: "请输入驳回原因", trigger: "blur"},],
                authState: [{required: true, message: "请选择审核状态", trigger: "change"},],
            },
            toExamineShow: false,
            loadingOptions: {
                lock: true,
                text: "Loading...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)"
            },
        };
    },
    methods: {
        // 选择
        formSearchvaluechange(e) {
            this.formPublish[this.formSearchvalue] = e;
        },
        //获取投诉列表
        getFeedbackTableData(params) {
            return getFeedbackList(params);
        },
        //查询
        handleSearch() {
            this.$refs["orderTableRef"].getTableData();
        },
        //重置
        handleReset() {
            this.formSearch = {};
            Promise.resolve(this.formSearch).then(() => this.handleSearch());
        },
        //处理
        handle(data) {
            const {uuid} = data;
            this.formPublish.uuid = uuid;
            this.dialogVisible = true;
        },
        //处理确认
        clickSubmit() {
            let that = this;
            // 表单提交
            that.$refs["formPublish"].validate((valid) => {
                if (valid) {
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading({...loadingOptions});
                    const {uuid, feedContent} = that.formPublish;
                    feedbackDealFeed({uuid, feedContent}).then((res) => {
                        MessageSuccess("投诉处理申请成功");
                        that.clickCancel();
                    }).finally(() => loading.close());
                }
            });
        },
        //处理取消
        clickCancel() {
            this.$refs["formPublish"].resetFields();
            this.handleSearch();
            this.dialogVisible = false;
        },
        //审核
        toExamineShowTrue(data) {
            this.formPublish = data;
            this.toExamineShow = true;
        },
        //审核确认
        toExamineSubmit() {
            let that = this;
            that.$refs["formPublish1"].validate((valid) => {
                if (valid) {
                    const loadingOptions = that.loadingOptions;
                    const loading = that.$loading({...loadingOptions});
                    const {uuid, authFeedContent, authState} = that.formPublish;
                    const data = {uuid, authFeedContent, authState}
                    authFeed(data).then((res) => {
                        MessageSuccess("投诉处理成功");
                        that.toExamineCancel();
                    }).finally(() => loading.close());
                }
            });
        },
        //审核取消
        toExamineCancel() {
            this.formPublish = {feedContent: null, uuid: null, authFeedContent: null, authState: null,};
            this.$refs["formPublish1"].resetFields();
            this.handleSearch();
            this.toExamineShow = false;
        },
        //获取按钮权限数组
        getButtonMenu(menu) {
            return JSON.parse(buttonCookies.get()).map((item) => item.url).some((v) => v === menu);
        },
    },
};
</script>

<style lang="scss" scoped>
.form_selest {
    margin-top: 6px;
    width: 120px;
    text-align: center;
    margin-left: -22px;
    padding-left: 12px;
    vertical-align: middle;
}

.complaint {
    .top-bar {
        display: flex;
        margin: VH(10px) 0 VH(25px);
        padding: VH(15px) VW(15px);
        justify-content: flex-start;
    }

    /deep/ .r-e-table {
        .el-table .cell {
            white-space: nowrap;
            text-align: center;
        }
    }
}

.chaxun_btn {
    margin-left: 12px;
}
</style>
