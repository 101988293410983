<template>
    <!-- 运营情况 -->
    <div  class="operation-situation">
        <div class="operation-situation padding_10_15 flex align-center  justify-around" style="height: 65% !important;">
           
            <div class="operation-item statistics">
                <div class="item flex align-center" v-for="item in operationOptions" :key="item.name">
                    <img class="label" :src="item.src" alt="">
                    <div class="value" :style="{ color: item.color }">{{ item.number || 0 }}</div>
                    <!-- 张晓瑜数据暂时修改 -->
                    <!-- <div class="value" :style="{ color: item.color }">{{ item.number=== 27 ? '256' : '270'}}</div> -->

                </div>
            </div>
            <div class="operation-item e-charts">
                <div id="statisticsECharts"></div>
                <div class="rate">{{ rate }} <span>%</span></div>
                <!--   张晓瑜数据暂时修改 --> 
                <!-- <div class="rate">94.8<span>%</span></div> -->

            </div>


        </div>
        <div class="operation-item slider">
            <div class="slider-item flex align-center" v-for="item in operationSlider" :key="item.name">
                <div class="label">{{ item.label }}</div>
                <el-slider style="width:600px" :class="item.name" :value="item.rate" disabled/>
                <div class="value">{{ item.value }}</div>
            </div>
        </div>
    </div>

</template>

<script>
import {operationOptions, operationECharts, operationSlider} from "@/views/home/data";
import * as echarts from "echarts";
import {getCommunityTotalApi, maintianFeedback} from "@/api/home";

export default {
    name: "operation-situation",
    data() {
        return {
            operationOptions,
            operationSlider,
            rate: 0,
        };
    },
    props: {
        collectionData: {
            type: Object,
            default: () => ({})
        }
    },
    methods: {
        // 初始化eCharts
        async eChartsInit(paramData) {
            try {
                //获取展示数据
                const {info} = await getCommunityTotalApi(paramData) || {};
                // 统计和echarts
                this.statisticsECharts = echarts.init(document.getElementById("statisticsECharts"));
                //处理数据
                this.operationOptions = operationOptions.map(v => {
                    return {
                        ...v,
                        value: info[v.label] || 0,
                        name: v.desc,
                        number: info[v.label === "空置" ? "合计" : v.label] || 0,
                    };
                });
                const seriesData = this.operationOptions;
                this.rate = (this.operationOptions[1].number / this.operationOptions[0].number * 100).toFixed(2);
                //给eCharts赋值
                operationECharts.series[0].data = seriesData;
                this.statisticsECharts.setOption(operationECharts, true);
                // 添加窗体拉伸事件
                window.addEventListener("resize", () => {
                    this.statisticsECharts.resize();
                });
            } catch (e) {
                console.error("首页统计,获取小区数量", e);
            }

        },

        //获取右边数据
        async maintianFeedback(paramData) {
            let res = await maintianFeedback(paramData);
            let {info: {complaint, repairs, maintain, signing}} = res;
            let {operationSlider} = this;
            for (let item of operationSlider) {
                if (item.label === "投诉量") {
                    item.rate = complaint;
                    item.value = complaint;
                }
                if (item.label === "报修量") {
                    item.rate = repairs;
                    item.value = repairs;
                }
                if (item.label === "已维修量") {
                    item.rate = maintain;
                    item.value = maintain;
                }
                if (item.label === "新签约") {
                    item.rate = signing;
                    item.value = signing;
                }
            }
            this.operationSlider = operationSlider;
        }
    },
    mounted() {
        //this.eChartsInit();
        let value = this.collectionData;
        const {startDate = null, endDate = null} = value;
        if (startDate && endDate) {
            this.maintianFeedback(value);
            this.eChartsInit(value);
        }

    },
    beforeDestroy() {
        // 移除窗体拉伸事件
        window.removeEventListener("resize", () => {
            this.statisticsECharts.resize();
        });
    },
    watch: {
        collectionData(value) {
            this.maintianFeedback(value);
            this.eChartsInit(value);
        }
    }
};
</script>

<style lang="scss" scoped>
.operation-situation {
  height: 100%;

  .operation-item {
    height: 100%;
  }

  .statistics {
    width: 23%;

    .item {
      height: 50%;
      padding-left: VW(15px);

     span{
       .yuntitle{
         width: VW(100px);
         color: #33558B;
         font-weight: 800;
         font-size: rem(22px);

       }
     }
      .label {
        width: VW(130px);
      }

      .value {
        font-size: rem(50px);
        padding-left: VW(15px);
        font-weight: 600;
        font-family: "PingFang SC Semibold";
      }
    }
  }

  .e-charts {
    width: 100%;
    position: relative;
    margin-left: 110px;

    #statisticsECharts {
      width: 100%;
      height: 100%;
    }

    .rate {
      color: #333;
      font-size: rem(30px);
      position: absolute;
      top: 42%;
      left: 35%;
      z-index: 1;
      font-weight: 600;
      font-family: "PingFang SC Semibold";

      span {
        font-size: rem(20px);
      }
    }
  }

  .slider {
    width: 100%;
    padding: VH(20px) 0 0 VW(20px);
    //border-left: 1px dashed #D7D7D7;

    .slider-item {
      padding-bottom: VH(10px);

      .label {
        width: VW(300px);
        color: #33558B;
        font-weight: 600;
        font-size: rem(18px);
      }

      .value {
        width: VW(80px);
        padding: 0 VW(15px);
        word-break: break-all
      }

      .rate {
        width: VW(90px);
      }

      /deep/ .el-slider {
        width: VW(800px);

        .el-slider__runway, .el-slider__bar {
          height: VH(13px);
          border-radius: 9px;
        }

        .el-slider__button-wrapper {
          display: none;
        }

        &.a1 .el-slider__bar {
          background-color: #FF1A00;
        }

        &.a2 .el-slider__bar {
          background-color: #A1DD70;
        }

        &.a3 .el-slider__bar {
          background-color: #FF7C33;
        }

        &.a4 .el-slider__bar {
          background-color: #DF10FF;
        }
      }
    }
  }
}
</style>
